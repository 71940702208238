import Container from '../Layout/Container';
import LoadingDataTable from '../Data/LoadingDataTable';

function LoadingScreen() {
  return (
    <Container>
      <h1>Loading…</h1>
      <LoadingDataTable />
    </Container>
  );
}

export default LoadingScreen;
