import PropTypes from 'prop-types';

const FormContainer = ({ children, heading, description }) => (
  <div className="md:grid md:grid-cols-3 md:gap-6">
    <div className="md:col-span-1">
      <div className="px-4 sm:px-0">
        {heading && <h3 className="text-lg font-medium leading-6 text-gray-900">{heading}</h3>}
        {description && <p className="mt-1 text-sm text-gray-600">{description}</p>}
      </div>
    </div>
    <div className="mt-5 md:mt-0 md:col-span-2">{children}</div>
  </div>
);

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  description: PropTypes.string,
};

FormContainer.defaultProps = {
  heading: null,
  description: null,
};

export default FormContainer;
