import { toast } from 'react-toastify';

const TOAST_DEFAULT_SETTINGS = {
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'colored',
};

const SUPPORTED_AUTH_PROVIDERS = ['twitter.com'];

export default { TOAST_DEFAULT_SETTINGS, SUPPORTED_AUTH_PROVIDERS };
