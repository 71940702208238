import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoadingDataTable from './LoadingDataTable';
import { firebaseTimestampToDate, getYearsFromEntries } from '../../lib/utils';
import PublicDataTableRow from './PublicDataTableRow';

function PublicDataTable({ entries, status, filter, slug }) {
  if (status === 'loading') {
    return <LoadingDataTable />;
  }
  const tableData =
    filter !== 'all'
      ? entries.filter((item) => firebaseTimestampToDate(item.completedDate).getFullYear() === filter)
      : entries;
  const filters = getYearsFromEntries(entries);

  let runningTotal = 0;
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="flex flex-row justify-start mb-8">
            <span className="text-gray-600 dark:text-gray-300">Filter by year:</span>
            <ul className="flex flex-row justify-start">
              <li>
                <Link
                  to={`/profile/${slug}/all`}
                  className={`${
                    filter === 'all'
                      ? 'bg-pink-700 text-white'
                      : 'bg-gray-200 text-black hover:bg-pink-600 hover:text-white'
                  } rounded-md px-2 py-1 mx-4`}
                >
                  All
                </Link>
              </li>
              {filters
                .sort((a, b) => b - a)
                .map((item) => (
                  <li key={item}>
                    <Link
                      to={`/profile/${slug}/${item}`}
                      className={`${
                        item === filter
                          ? 'bg-pink-700 text-white'
                          : 'bg-gray-200 text-black hover:bg-pink-600 hover:text-white'
                      } rounded-md px-2 py-1 mx-4`}
                    >
                      {item}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="shadow overflow-hidden border-b border-gray-200 dark:border-gray-900 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-900">
              <thead className="bg-gray-50 dark:bg-gray-900">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-600 dark:text-gray-200 uppercase tracking-wider"
                  >
                    Game/Brand
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-600 dark:text-gray-200 uppercase tracking-wider"
                  >
                    What did you paint?
                    <small className="block">e.g. Steampunk Lemmings</small>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-600 dark:text-gray-200 uppercase tracking-wider"
                  >
                    Number of Models
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-600 dark:text-gray-200 uppercase tracking-wider"
                  >
                    Running Total
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-600 dark:text-gray-200 uppercase tracking-wider"
                  >
                    Date of Completion
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:text-gray-50 dark:bg-gray-700 dark:divide-gray-900">
                {tableData.map((entry, index) => {
                  runningTotal += entry.modelCount;
                  return (
                    <PublicDataTableRow
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${entry.id}-${index}`}
                      entry={entry}
                      runningTotal={runningTotal}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

PublicDataTable.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.object),
  status: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.any,
  slug: PropTypes.string.isRequired,
};
PublicDataTable.defaultProps = {
  status: null,
  entries: [],
  filter: new Date().getFullYear(),
};

export default PublicDataTable;
