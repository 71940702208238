import { Link } from 'react-router-dom';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="max-w-7xl mx-auto my-4 px-4 sm:px-6 lg:px-8 dark:text-gray-400">
      <p>
        <Link
          className="underline text-pink-800 hover:text-gray-800 dark:text-pink-300 dark:hover:text-pink-500"
          to="/"
        >
          I Painted This!
        </Link>{' '}
        - Copyright &copy; 2021 {year !== 2021 && <>&ndash; {year}</>} Chris McLeod.{' '}
        <Link
          className="underline text-pink-800 hover:text-gray-800 dark:text-pink-300 dark:hover:text-pink-500"
          to="/log"
        >
          Painting Log
        </Link>{' '}
        inspired by{' '}
        <a
          href="http://www.paintslam.com/"
          rel="noopener noreferrer"
          target="_blank"
          className="underline text-pink-800 hover:text-gray-800 dark:text-pink-300 dark:hover:text-pink-500"
        >
          PaintSlam by JewelKnightJess
        </a>
        .
      </p>
    </footer>
  );
}

export default Footer;
