import { collection, orderBy, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import PropTypes from 'prop-types';
import { getYearsFromEntries } from '../../lib/utils';
import PublicDataTable from '../Data/PublicDataTable';

function Profile({ profile, filter }) {
  const fireStore = useFirestore();

  const [years, setYears] = useState(0);
  const [allTimeStats, setAllTimeStats] = useState(0);
  const [thisYearsStats, setThisYearsStats] = useState(0);

  const entriesCollection = collection(fireStore, 'entries');
  const entriesQuery = query(
    entriesCollection,
    where('userId', '==', profile.id),
    orderBy('completedDate', 'asc'),
    orderBy('createdAt', 'asc')
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { status, data: entries } = useFirestoreCollectionData(entriesQuery, {
    idField: `id`,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    document.title = `Painting Log for ${profile.displayName} - I Painted This!`;
    if (status !== 'loading' && status !== 'error') {
      setAllTimeStats(entries.reduce((a, b) => (Number.isNaN(b.modelCount) ? a : a + b.modelCount), 0));
      setThisYearsStats(
        entries
          .filter((item) => item.completedDate.toDate().getFullYear() === new Date().getFullYear())
          .reduce((a, b) => (Number.isNaN(b.modelCount) ? a : a + b.modelCount), 0)
      );
      setYears(getYearsFromEntries(entries).length);
    }
  }, [entries, status, years, profile.displayName]);

  return (
    <div>
      <div className="flex items-center px-5 w-full">
        <div className="flex-shrink-0">
          <img className="h-12 w-12 rounded-full" src={profile.avatar} alt="" />
        </div>
        <div className="mx-3 px-3 border-l dark:border-gray-900">
          <h2 className="text-2xl font-bold my-4 text-slate-800 dark:text-slate-100">
            Profile for {profile.displayName}.{' '}
            <small className="text-pink-600">Here&apos;s what they painted recently</small>
          </h2>
        </div>
        <div className="ml-auto px-2 hidden lg:grid border-l dark:border-gray-800 gap-1 grid-cols-2 grid-rows-3 items-baseline">
          <h3 className="text-l uppercase tracking-wider text-center text-gray-600 dark:text-gray-300 col-span-2">
            Miniatures logged:
          </h3>
          <span className="text-5xl text-pink-700 block text-right uppercase tracking-wider col-span-1 row-span-1">
            {allTimeStats}
          </span>
          <small className="text-4xl col-span-1 row-span-1 dark:text-gray-200">in {years} years</small>
          <span className="text-3xl text-pink-500 block uppercase tracking-wider text-right col-span-1 row-span-1">
            {thisYearsStats}
          </span>
          <small className="text-2xl col-span-1 row-span-1 dark:text-gray-300">this year</small>
        </div>
      </div>
      <PublicDataTable entries={entries} status={status} filter={filter} slug={profile.slug} />
    </div>
  );
}
Profile.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    avatar: PropTypes.string,
    isPublic: PropTypes.bool,
    slug: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.any,
};
Profile.defaultProps = {
  filter: undefined,
};

export default Profile;
