import { collection, query, where } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { SuspenseWithPerf, useFirestore, useFirestoreCollectionData } from 'reactfire';
import Container from '../Layout/Container';
import ErrorScreen from './ErrorScreen';
import LoadingScreen from './LoadingScreen';
import Profile from './Profile';

function PublicProfile() {
  const fireStore = useFirestore();
  // eslint-disable-next-line prefer-const
  let { uid, filter } = useParams();

  const profilesCollection = collection(fireStore, 'profiles');
  const profilesQuery = query(profilesCollection, where('slug', '==', uid));
  const { data: profile } = useFirestoreCollectionData(profilesQuery, {
    idField: `id`,
  });

  if (profile.length === 0 || profile[0].isPublic === false) {
    return <ErrorScreen error="User profile not found" />;
  }

  if (filter !== 'all') {
    filter = parseInt(filter, 10);
    filter = Number.isNaN(filter) ? undefined : filter;
  }

  const userProfile = profile[0];

  return (
    <Container>
      <SuspenseWithPerf fallback={<LoadingScreen />}>
        <Profile profile={userProfile} filter={filter} />
      </SuspenseWithPerf>
    </Container>
  );
}

export default PublicProfile;
