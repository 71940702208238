// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyABEVLcx5FDihyfvDNWs-OhiekP9PRPnaY',
  authDomain: 'paintslam-tracker.firebaseapp.com',
  projectId: 'paintslam-tracker',
  storageBucket: 'paintslam-tracker.appspot.com',
  messagingSenderId: '362912028468',
  appId: '1:362912028468:web:31574b732c815c3f02b761',
  measurementId: 'G-G8VBDH2ZMR',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export { app, firebaseConfig as config };
