import { collection, deleteDoc, orderBy, query, where, doc, addDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData, useUser } from 'reactfire';

import { useParams } from 'react-router-dom';
import { HiOutlineLockClosed, HiOutlineLockOpen } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Container from '../Layout/Container';
import DataTable from '../Data/DataTable';
import AddEntryForm from '../Forms/AddEntryForm';
import { getYearsFromEntries } from '../../lib/utils';

import TOAST_DEFAULT_SETTINGS from '../../lib/consts';

function PaintingLog() {
  const fireStore = useFirestore();
  const { data: user } = useUser();
  const entriesCollection = collection(fireStore, 'entries');
  const entriesQuery = query(
    entriesCollection,
    where('userId', '==', user.uid),
    orderBy('completedDate', 'asc'),
    orderBy('createdAt', 'asc')
  );
  const { status, data: entries } = useFirestoreCollectionData(entriesQuery, {
    idField: `id`,
  });
  const ref = doc(fireStore, 'profiles', user.uid);
  let { data: settings } = useFirestoreDocData(ref);
  if (!settings) {
    settings = { isPublic: false };
  }
  const [allTimeStats, setAllTimeStats] = useState(0);
  const [thisYearsStats, setThisYearsStats] = useState(0);
  const [years, setYears] = useState(0);

  useEffect(() => {
    document.title = `Painting Log for ${user.displayName} - I Painted This!`;
    if (status !== 'loading' && status !== 'error') {
      setAllTimeStats(entries.reduce((a, b) => (Number.isNaN(b.modelCount) ? a : a + b.modelCount), 0));
      setThisYearsStats(
        entries
          .filter((item) => item.completedDate.toDate().getFullYear() === new Date().getFullYear())
          .reduce((a, b) => (Number.isNaN(b.modelCount) ? a : a + b.modelCount), 0)
      );
      setYears(getYearsFromEntries(entries).length);
    }
  }, [entries, status, years, user.displayName]);

  let { filter } = useParams();

  if (filter !== 'all') {
    filter = parseInt(filter, 10);
    filter = Number.isNaN(filter) ? undefined : filter;
  }

  if (status === 'error') {
    return <p>Error</p>;
  }

  const deleteEntry = (entryId) => {
    deleteDoc(doc(entriesCollection, entryId));
  };

  const addEntry = (entry) => {
    addDoc(entriesCollection, entry)
      .then(() => {
        toast.success(`🎉 Added log entry for "${entry.item}"`, TOAST_DEFAULT_SETTINGS);
      })
      .catch(() => {
        toast.error(`Sorry, something went wrong while adding your log entry`, TOAST_DEFAULT_SETTINGS);
      });
  };

  return (
    <Container>
      <div className="flex items-center px-5 w-full">
        <div className="flex-shrink-0">
          <img className="h-12 w-12 rounded-full" src={user.photoURL} alt="" />
        </div>
        <div className="mx-3 px-3 border-l dark:border-gray-900">
          <h2 className="text-2xl font-bold my-4 text-slate-800 dark:text-slate-100">
            Hello {user.displayName}. <small className="text-pink-600">What have you painted recently?</small>
          </h2>
          <div className="text-sm font-medium leading-none text-gray-500 dark:text-gray-400">
            {settings.isPublic === false ? (
              <span className="mr-2 shadow-md border border-slate-400 bg-slate-300 text-slate-800 rounded-full px-2 py-1 inline-flex items-center">
                <HiOutlineLockClosed className="mr-1" /> Private profile
              </span>
            ) : (
              <>
                <span className="mr-2 shadow-md border border-green-400 bg-green-300 text-green-800 rounded-full px-2 py-1 inline-flex items-center">
                  <HiOutlineLockOpen className="mr-1" /> Public profile
                </span>{' '}
                viewable <a href={`/profile/${settings.slug}`}>here</a>
              </>
            )}
          </div>
        </div>
        <div className="ml-auto px-2 hidden lg:grid border-l dark:border-gray-800 gap-1 grid-cols-2 grid-rows-3 items-baseline">
          <h3 className="text-l uppercase tracking-wider text-center text-gray-600 dark:text-gray-300 col-span-2">
            Miniatures logged:
          </h3>
          <span className="text-5xl text-pink-700 block text-right uppercase tracking-wider col-span-1 row-span-1">
            {allTimeStats}
          </span>
          <small className="text-4xl col-span-1 row-span-1 dark:text-gray-200">in {years} years</small>
          <span className="text-3xl text-pink-500 block uppercase tracking-wider text-right col-span-1 row-span-1">
            {thisYearsStats}
          </span>
          <small className="text-2xl col-span-1 row-span-1 dark:text-gray-300">this year</small>
        </div>
      </div>

      <div className="my-8">
        <AddEntryForm addAction={addEntry} user={user} />
      </div>
      <DataTable entries={entries} status={status} filter={filter} deleteAction={deleteEntry} />
    </Container>
  );
}

export default PaintingLog;
