import { getAuth } from 'firebase/auth';
import { BrowserRouter } from 'react-router-dom';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { AuthProvider, useFirebaseApp, SuspenseWithPerf, AppCheckProvider } from 'reactfire';
import Main from './components/Layout/Main';
import LoadingScreen from './components/Screens/LoadingScreen';

const APP_CHECK_TOKEN = '6LdCMsYdAAAAAEY1WFXzp6ASUwtsgM0ednsDOgwl';

function App() {
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);

  const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });

  return (
    <div className="min-h-full bg-gray-100 dark:bg-gray-700">
      <AppCheckProvider sdk={appCheck}>
        <AuthProvider sdk={auth}>
          <SuspenseWithPerf fallback={<LoadingScreen />} traceId="loading-app-status">
            <BrowserRouter>
              <Main />
            </BrowserRouter>
          </SuspenseWithPerf>
        </AuthProvider>
      </AppCheckProvider>
    </div>
  );
}

export default App;
