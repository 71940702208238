/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useField } from 'formik';

const FormField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label className="block text-sm font-semibold text-gray-700 dark:text-gray-200" htmlFor={props.id || props.name}>
        {label}
      </label>
      <input
        className={`mt-1 focus:outline-none focus:shadow-outline block w-full shadow-sm sm:text-sm text-gray-800 dark:text-gray-50 dark:bg-gray-700 rounded-md ${
          meta.touched && meta.error ? 'border-red-500' : 'border-gray-300 dark:border-gray-900'
        }`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? <div className="text-red-500 text-xs italic">{meta.error}</div> : null}
    </>
  );
};

export default FormField;
