import PropTypes from 'prop-types';
import { HiExclamationCircle } from 'react-icons/hi';
import Container from '../Layout/Container';

function ErrorScreen({ error }) {
  return (
    <Container>
      <div className="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <div className="py-1">
          <HiExclamationCircle className="h-8 w-8" />
        </div>
        <div>
          <h1 className="text-3xl font-bold ">Error:</h1>
          <p className="my-4">{error}</p>
        </div>
      </div>
    </Container>
  );
}

ErrorScreen.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorScreen;
