import PropTypes from 'prop-types';

const Container = ({ children }) => (
  <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 bg-white my-5 rounded-md border shadow-sm dark:bg-gray-800 dark:border-gray-900 dark:text-gray-200">
    {children}
  </main>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
