import PropTypes from 'prop-types';
import { Disclosure } from '@headlessui/react';
import { HiOutlineMenu as MenuIcon, HiOutlineX as XIcon } from 'react-icons/hi';
import { useAuth, useUser } from 'reactfire';
import { Link, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';
import { ReactComponent as Logo } from '../../logo.svg';
import TOAST_DEFAULT_SETTINGS from '../../lib/consts';

const navigation = [
  { name: 'Painting Log', href: '/log' },
  { name: 'Settings', href: '/settings' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Header() {
  const auth = useAuth();
  const { data: user } = useUser();
  const location = useLocation();

  const signOut = async () => {
    await auth.signOut().then(() => {
      toast.success('You have been signed out', TOAST_DEFAULT_SETTINGS);
    });
  };

  const isCurrentLink = (href) => location.pathname.startsWith(href);

  return (
    <>
      <Disclosure as="nav" className="bg-yellow-300 dark:bg-gray-900 ">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link to="/">
                      <span className="sr-only">I Painted This!</span>
                      <Logo className="w-12 h-12 text-pink-600 hover:text-pink-800" />
                    </Link>
                  </div>
                  {user && (
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              isCurrentLink(item.href)
                                ? 'bg-yellow-100 text-pink-700 dark:bg-slate-700 dark:text-pink-500'
                                : 'text-pink-700 hover:bg-yellow-100 hover:text-pink-800 dark:hover:bg-slate-700 dark:text-pink-500 dark:hover:text-pink-100',
                              'px-3 py-2 rounded-md text-lg font-bold'
                            )}
                            aria-current={item.href === location.pathname ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {user ? (
                      <button
                        type="button"
                        onClick={() => {
                          signOut();
                        }}
                        className="block px-4 py-2 text-sm text-pink-700 w-full text-left font-bold rounded-md ml-2 dark:bg-slate-900 dark:hover:bg-slate-700 dark:text-pink-500 dark:hover:text-pink-100 bg-yellow-200 hover:bg-yellow-100 hover:text-pink-700"
                      >
                        Sign Out
                      </button>
                    ) : (
                      <Link
                        to="/login"
                        className="px-3 py-2 rounded-md text-lg font-bold text-pink-700 hover:bg-yellow-100 hover:text-pink-800 dark:hover:bg-slate-700 dark:text-pink-500 dark:hover:text-pink-100"
                        aria-current={location.pathname === 'login' ? 'page' : undefined}
                      >
                        Sign In/Join
                      </Link>
                    )}
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-yellow-300 inline-flex items-center justify-center p-2 rounded-md text-pink-600 hover:bg-yellow-100 hover:text-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-pink-600 focus:ring-yellow-100 dark:hover:bg-slate-700 dark:text-gray-50 dark:hover:text-pink-100 dark:bg-gray-900">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as={Link}
                    to={item.href}
                    className={classNames(
                      isCurrentLink(item.href)
                        ? 'bg-yellow-100 text-pink-700 dark:bg-slate-700 dark:text-pink-500'
                        : 'text-pink-600 hover:bg-yellow-100 hover:text-pink-700 dark:hover:bg-slate-700 dark:text-pink-500 dark:hover:text-pink-100',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.href === location.pathname ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              {user ? (
                <div className="pt-4 pb-3 border-t border-gray-700 dark:border-gray-900">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.photoURL} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{user.displayName}</div>
                      <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    <Disclosure.Button
                      onClick={() => {
                        signOut();
                      }}
                      className="block px-4 py-2 text-sm text-pink-700 w-full text-left font-bold rounded-md ml-2 dark:bg-slate-900 dark:hover:bg-slate-700 dark:text-pink-500 dark:hover:text-pink-100 bg-yellow-200 hover:bg-yellow-100 hover:text-pink-700"
                    >
                      Sign Out
                    </Disclosure.Button>
                  </div>
                </div>
              ) : (
                <div className="pt-4 pb-3 border-t border-gray-700 dark:border-gray-900">
                  <div className="mt-3 px-2 space-y-1">
                    <Disclosure.Button
                      as={Link}
                      to="/login"
                      className="block px-4 py-2 text-sm text-pink-700 w-full text-left font-bold rounded-md ml-2 dark:bg-slate-900 dark:hover:bg-slate-700 dark:text-pink-500 dark:hover:text-pink-100 bg-yellow-200 hover:bg-yellow-100 hover:text-pink-700"
                    >
                      Sign In/Join
                    </Disclosure.Button>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <header className="bg-white shadow dark:bg-gray-800">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-slate-100  tracking-wider">
            <span className="uppercase">I Painted This!</span>
          </h1>
        </div>
      </header>
    </>
  );
}
Header.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string,
    photoURL: PropTypes.string,
    email: PropTypes.string,
    uid: PropTypes.string,
  }),
};
Header.defaultProps = {
  user: null,
};

export default Header;
