import PropTypes from 'prop-types';
import { firebaseTimestampToDate } from '../../lib/utils';

function PublicDataTableRow({ entry, runningTotal }) {
  return (
    <tr>
      <td className="px-6 py-4 whitespace-normal">
        <div className="text-sm text-gray-500 dark:text-gray-300 break-words">{entry.game}</div>
      </td>
      <td className="px-6 py-4 whitespace-normal">
        <div className="flex items-center">
          {/* {entry.image && (
            <div className="flex-shrink-0 h-10 w-10 mr-4">
              <img className="h-10 w-10 rounded-full" src={entry.image} alt="" />
            </div>
          )} */}
          <div className="font-medium text-gray-900 text-lg break-words dark:text-slate-50">{entry.item}</div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center">
        <div className="text-sm text-gray-900 dark:text-slate-300">{entry.modelCount}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100">
          {runningTotal}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-500 dark:text-gray-300">
          {firebaseTimestampToDate(entry.completedDate).toDateString()}
        </div>
      </td>
    </tr>
  );
}

PublicDataTableRow.propTypes = {
  entry: PropTypes.shape({
    game: PropTypes.string,
    item: PropTypes.string,
    completedDate: PropTypes.shape({
      seconds: PropTypes.number,
      nanoseconds: PropTypes.number,
    }),
    modelCount: PropTypes.number,
    id: PropTypes.string,
  }).isRequired,
  runningTotal: PropTypes.number,
};

PublicDataTableRow.defaultProps = {
  runningTotal: 0,
};

export default PublicDataTableRow;
