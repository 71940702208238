/* eslint-disable react/button-has-type */
import { Timestamp } from 'firebase/firestore';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useUser } from 'reactfire';
import FormField from './FormField';

function AddEntryForm({ addAction }) {
  const currentDate = new Date().toISOString().split('T');
  const { data: user } = useUser();

  return (
    <Formik
      initialValues={{
        game: '',
        item: '',
        completedDate: currentDate[0],
        modelCount: 1,
      }}
      validationSchema={Yup.object({
        game: Yup.string().required('Please fill this in'),
        item: Yup.string().required('Please tell us what you painted'),
        modelCount: Yup.number()
          .min(1, 'The minimum model count is 1')
          .required('Please tell us how many models you painted'),
        completedDate: Yup.date().required('Please tell us when you finished this entry'),
      })}
      onSubmit={({ game, item, completedDate, modelCount }, { setSubmitting, resetForm }) => {
        const entry = {
          game,
          item,
          completedDate: Timestamp.fromDate(new Date(completedDate)),
          modelCount: parseInt(modelCount, 10),
          userId: user.uid,
          createdAt: Timestamp.now(),
        };
        addAction(entry);
        resetForm();
        setSubmitting(false);
      }}
    >
      <Form className="shadow overflow-hidden sm:rounded-md grid grid-cols-12 gap-6 p-4 border dark:border-gray-900 dark:bg-opacity-50 dark:bg-gray-900">
        <div className="col-span-12 sm:col-span-12 md:col-span-3">
          <FormField label="Game/Brand" id="game" name="game" placeholder="e.g. Infinity/Age of Sigmar" type="text" />
        </div>
        <div className="col-span-12 sm:col-span-12  md:col-span-5">
          <FormField label="What did you paint?" id="item" name="item" placeholder="Steampunk Lemmings" type="text" />
        </div>
        <div className="col-span-6 sm:col-span-6 md:col-span-2">
          <FormField label="Number of models" id="modelCount" name="modelCount" type="number" />
        </div>
        <div className="col-span-6 sm:col-span-6 md:col-span-2">
          <FormField label="Date of completion" id="completedDate" name="completedDate" type="date" />
        </div>
        <div className="col-span-12 text-right">
          <button
            type="submit"
            className="inline-flex items-center py-2 px-4 mr-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-600 hover:bg-pink-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            Add
          </button>
          <button
            type="reset"
            className="inline-flex items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-800 bg-gray-300 hover:bg-gray-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            Cancel
          </button>
        </div>
      </Form>
    </Formik>
  );
}
AddEntryForm.propTypes = {
  addAction: PropTypes.func.isRequired,
};

export default AddEntryForm;
