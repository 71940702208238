import { enableIndexedDbPersistence, initializeFirestore } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { FirestoreProvider, useInitFirestore } from 'reactfire';

function FirestoreWrapper({ children }) {
  const { data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const db = initializeFirestore(firebaseApp, {});
    try {
      await enableIndexedDbPersistence(db);
    } catch (e) {
      // console.log(`Error enabling persistence: ${JSON.stringify(e)}`);
    }

    return db;
  });

  return <FirestoreProvider sdk={firestoreInstance}>{children}</FirestoreProvider>;
}

FirestoreWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FirestoreWrapper;
