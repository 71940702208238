import { TwitterAuthProvider, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FaTwitter, FaGoogle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'reactfire';
import Container from '../Layout/Container';

function LoginScreen() {
  const auth = useAuth();
  const navigate = useNavigate();

  const signInWithTwitter = async () => {
    await signInWithPopup(auth, new TwitterAuthProvider());
    navigate('/log');
  };
  const signInWithGoogle = async () => {
    await signInWithPopup(auth, new GoogleAuthProvider());
    navigate('/log');
  };

  return (
    <Container>
      <div className="flex items-center justify-center flex-col">
        <div className="grid grid-flow-rows grid-cols-5 gap-2">
          <p className="text-xl font-normal text-justify col-span-5 md:col-span-3 md:col-start-2">
            <strong>I Painted This!</strong> is a web application for logging all of the miniatures you paint in a year,
            inpired by the annual{' '}
            <a
              href="https://paintslam.com/"
              rel="noreferrer noopener"
              target="_blank"
              className="text-pink-600 underline hover:text-pink-800 hover:no-underline"
            >
              PaintSlam
            </a>{' '}
            community event. Use it to keep track of the number of models you paint for each game, unit, or model type,
            and see your running totals throughout the year. It helps you see your progress as you go - and hopefully
            stay motivated!
          </p>
          <h2 className="text-2xl font-bold mt-8 mb-4 col-span-5 md:col-span-3 md:col-start-2">
            To get started, login with one of the following providers:
          </h2>
          <button
            type="button"
            onClick={() => {
              signInWithTwitter();
            }}
            className="hover:bg-blue-500 bg-blue-700 text-white font-bold py-2 px-4 mb-2 rounded inline-flex items-center col-span-3 col-start-2 justify-center border border-blue-800"
          >
            <FaTwitter className=" h-6 w-6 m-1" /> Twitter
          </button>
          <button
            type="button"
            onClick={() => {
              signInWithGoogle();
            }}
            className="bg-gray-400 hover:bg-gray-200 text-gray-900 font-bold py-2 px-4 mb-2 rounded border inline-flex items-center col-span-3 col-start-2 justify-center border-gray-600"
          >
            <FaGoogle className=" h-6 w-6 m-1" /> Google
          </button>
        </div>
      </div>
    </Container>
  );
}

export default LoginScreen;
