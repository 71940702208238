import PropTypes from 'prop-types';
import { useSigninCheck } from 'reactfire';
import LoadingScreen from '../Screens/LoadingScreen';

function RequireAuth({ children, fallback }) {
  const { status, data: signInCheckResult } = useSigninCheck();
  if (status === 'loading') {
    return <LoadingScreen />;
  }
  if (!signInCheckResult.signedIn) {
    return fallback;
  }
  return children;
}
RequireAuth.propTypes = {
  children: PropTypes.element.isRequired,
  fallback: PropTypes.element.isRequired,
};

export default RequireAuth;
