import PropTypes from 'prop-types';

const FormRow = ({ children, isButtonRow }) => (
  <div className={`px-4 py-3 sm:px-6 ${isButtonRow ? 'bg-gray-50 text-right' : ''}`}>{children}</div>
);
FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  isButtonRow: PropTypes.bool,
};
FormRow.defaultProps = {
  isButtonRow: false,
};

export default FormRow;
