/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useUser } from 'reactfire';
import { updateProfile } from 'firebase/auth';
import { toast } from 'react-toastify';
import Container from '../Layout/Container';
import TOAST_DEFAULT_SETTINGS from '../../lib/consts';
import FormField from './FormField';
import FormRow from '../Layout/FormRow';
import FormSection from '../Layout/FormSection';
import FormContainer from '../Layout/FormContainer';

const SettingsForm = () => {
  const { data: user } = useUser();
  return (
    <Container>
      <FormContainer
        heading={`Profile settings for ${user.displayName}`}
        description="This information can be displayed publicly, if you enable your public profile, so be careful what you
              share."
      >
        <Formik
          initialValues={{
            displayName: user.displayName,
          }}
          validationSchema={Yup.object({
            displayName: Yup.string(),
          })}
          onSubmit={({ displayName }, { setSubmitting, resetForm }) => {
            const profile = {};

            if (displayName && displayName !== user.displayName) {
              profile.displayName = displayName;
            }

            if (profile && profile !== {}) {
              updateProfile(user, profile)
                .then(() => {
                  toast.success('User profile updated', TOAST_DEFAULT_SETTINGS);
                  resetForm({
                    values: {
                      displayName: profile.displayName,
                    },
                  });
                  setSubmitting(false);
                })
                .catch(() => {
                  toast.error('Something went wrong while updating your user profile', TOAST_DEFAULT_SETTINGS);
                });
            }
          }}
        >
          <Form>
            <FormSection>
              <FormRow>
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <FormField label="Update your display name" id="displayName" name="displayName" type="text" />
                  </div>
                </div>
              </FormRow>
              <FormRow isButtonRow>
                <button
                  type="submit"
                  className="inline-flex items-center py-2 px-4 mr-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-600 hover:bg-pink-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  Save
                </button>
                <button
                  type="reset"
                  className="inline-flex items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-800 bg-gray-300 hover:bg-gray-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  Cancel
                </button>
              </FormRow>
            </FormSection>
          </Form>
        </Formik>
      </FormContainer>
    </Container>
  );
};
export default SettingsForm;
