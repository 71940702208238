import { Routes, Route, Navigate } from 'react-router-dom';
import SettingsForm from '../Forms/SettingsForm';
import LoginScreen from '../Screens/LoginScreen';
import PaintingLog from '../Screens/PaintingLog';
import PublicProfile from '../Screens/PublicProfile';
import FirestoreWrapper from './FirestoreWrapper';
import Layout from './Layout';
import RequireAuth from './RequireAuth';

function Main() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" exact element={<Navigate to="/log" />} />
        <Route
          path="/log"
          element={
            <RequireAuth fallback={<LoginScreen />}>
              <FirestoreWrapper>
                <PaintingLog />
              </FirestoreWrapper>
            </RequireAuth>
          }
        >
          <Route
            path=":filter"
            element={
              <RequireAuth fallback={<LoginScreen />}>
                <FirestoreWrapper>
                  <PaintingLog />
                </FirestoreWrapper>
              </RequireAuth>
            }
          />
        </Route>
        <Route path="/login" element={<LoginScreen />} />
        <Route
          path="/settings"
          element={
            <RequireAuth fallback={<LoginScreen />}>
              <FirestoreWrapper>
                <SettingsForm />
              </FirestoreWrapper>
            </RequireAuth>
          }
        />

        <Route
          path="/profile/:uid"
          element={
            <FirestoreWrapper>
              <PublicProfile />
            </FirestoreWrapper>
          }
        >
          <Route
            path=":filter"
            element={
              <FirestoreWrapper>
                <PublicProfile />
              </FirestoreWrapper>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default Main;
